import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";

const Pagination = ({ data }) => {
  return (
    <div className="pagination">
      {data.numberOfPages > 1 && (
      <p className="page-data">{data.humanPageNumber} / {data.numberOfPages}</p>
      )}
      <ul>
        {data.previousPagePath && (
          <li><Link to={data.previousPagePath}>前のページ</Link></li>
        )}
        {data.nextPagePath && (
          <li><Link to={data.nextPagePath}>次のページ</Link></li>
        )}
      </ul>

    </div>
  )
}

Pagination.defaultProps = {
  data: {}
}

Pagination.propTypes = {
  data: PropTypes.object,
}

export default Pagination
