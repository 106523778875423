import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import Seo from "../components/seo"
import Articles from "../components/articles"
import Pagination from "../components/pagination"

const Category = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const {category} = pageContext
  const posts = data.allMdx.nodes
  const title = "カテゴリー「" + category + "」の記事一覧"
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={title} />
      <h1 className="archive-title">{title}</h1>
      <Articles posts={posts} />
      <Pagination data={pageContext} />
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`    
    query($category: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC },
            filter: { frontmatter: { 
                category: { eq: $category },
                status: {eq: "publish"}
            } }
        ) {
            nodes {
                excerpt
                slug
                frontmatter {
                    date(formatString: "YYYY/MM/DD")
                    title
                    category
                    tag
                }
            }
        }
    }
`