import * as React from "react"
import PropTypes from "prop-types"
import PostMeta from "./post-meta";
import {Link} from "gatsby";

const Articles = ({ posts }) => {
  return (
    <ol style={{ listStyle: `none` }} className="articles">
      {posts.map(post => {
        const title = post.frontmatter.title || post.slug
        const post_url = '/' + post.slug
        return (
          <li key={post.slug}>
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <PostMeta date={post.frontmatter.date} categories={post.frontmatter.category} tags={post.frontmatter.tag} />
                <h2>
                  <Link to={post_url} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
              </header>
            </article>
          </li>
        )
      })}
    </ol>
  )
}

Articles.defaultProps = {
  posts: []
}

Articles.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
}

export default Articles
